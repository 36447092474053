<template>
    <uw-content title="流程管理" padding="10px">
        <!-- 表格 -->
        <uw-table :show-header="false" :show-footer="false">
            <!-- 数据 -->
            <template slot="data">
                <vxe-table ref="xTable" :data="table" :loading="loading" height="100%">
                    <!-- ID -->
                    <vxe-column width="60" type="seq" title="ID"></vxe-column>

                    <!-- 业务模块 -->
                    <vxe-column width="120" field="name" title="业务模块"></vxe-column>

                    <!-- 流程配置 -->
                    <vxe-column min-width="120">
                        <template #default="{ row }">
                            <workflow-update v-model="row.template" :model="row.model" @onChang="(data) => FormSubmit(row.id, data)" />
                        </template>
                    </vxe-column>
                </vxe-table>
            </template>
        </uw-table>
    </uw-content>
</template>

<script>
export default {
    data () {
        return {
            loading: false,

            table: [],

            // 检索
            search: {},

            // 排序
            order: {
                method: 'desc',
                column: 'id'
            },

            form: {
                id: null,
                template: null
            }
        }
    },

    mounted () {
        this.TableSearch()
    },
    
    methods: {
        /**
        |--------------------------------------------------
        | 查询数据
        |--------------------------------------------------
        |
        */

        TableSearch() {
            this.loading = true
            this.$http.post(
                '/9api/workflow-template/search-all',
                {
                    search: this.search,
                    order: this.order
                }
            ).then((rps) => {
                this.table = rps.data

                // 切换加载状态
                this.loading = false
            })
        },

        /**
        |--------------------------------------------------
        | 提交表单
        |--------------------------------------------------
        |
        */

        FormSubmit(id, data) {
            this.$http.post('/9api/workflow-template/update', { id: id, template: data }).then(rps => {
                this.TableSearch()
            })
        }
    }
}
</script>